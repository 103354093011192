import React, { useState } from 'react'
import './SignUp.css';
import axios from "axios";
import {baseURL} from '../../../Config.js';
import { Person,Envelope,Eye, Shop} from 'react-bootstrap-icons';
import { useForm } from "react-hook-form";
import { useNavigate } from 'react-router-dom';
import {authenticate_user,unauthenticate_user} from "../../../Redux/userAuthentication/userAuthenticationSlice"
import {useDispatch,useSelector} from "react-redux"
import {remove_user_details} from "../../../Redux/userDetails/userDetailsSlice"
import {remove_planDetails} from "../../../Redux/planDetails/planDetailsSlice"
import {set_isLoading} from "../../../Redux/isLoading/isLoadingSlice"

import HashLoader from "react-spinners/HashLoader";
import { Link } from '@mui/icons-material';

function SignUp(props) {
 
const regURL = 'api/vendor/registration'
const dispatch =useDispatch()
const isLoading = useSelector(state => state.isLoading.isLoading);



const {
  register,
  handleSubmit,
  formState: { errors },
  setValue 
} = useForm({
  mode: "all",
});

const handleStoreNameChange = (e) => {
  const inputValue = e.target.value;
  const formattedValue = inputValue
    .toLowerCase()
    .replace(/\s+/g, "-")          // Replace spaces with hyphens
    .replace(/[^a-z0-9-]/g, "");    // Remove special characters

  // setStoreUrl(formattedValue);
  setValue("username", formattedValue); // Update the username field in react-hook-form
};

//SignUp
const onSubmit = (data) => {
  dispatch(set_isLoading({isLoading:true}))
  axios
  .post(baseURL+regURL, {

    name: data.firstName,
    email:data.email,
    password: data.password,
    username:data.username,
    otp:data.otp,
    mobile:props.mobile,

    })
  .then((response) => {
    if(response.data.success)
    {
      localStorage.setItem('access_token', JSON.stringify(response.data.data.token));
      dispatch(authenticate_user({name:""}))
      dispatch(remove_user_details())
      dispatch(remove_planDetails())
      dispatch(set_isLoading({isLoading:false}))
      navigate("/")
     }
    else
    {
      dispatch(set_isLoading({isLoading:false}))
      setDisableError(false)
      setMessage(response.data.message);
      dispatch(unauthenticate_user())
     
    }
   
  });


}




//navigate to
const navigate = useNavigate();

//show error message
const [disableError, setDisableError] = useState(true)

//error message display 
const [message, setMessage] = useState("")

  return (
    <>
   {isLoading?<div className={isLoading ? 'parentDisableLight' : ''} width="100%">
      <HashLoader color={'#0d6efd'} loading={isLoading} size={50}  cssOverride={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }} />
    </div>:null}

    <div className="col-xl-7 col-md-6 col-lg-6  col-12">

 
         <div className='mt-3'>


<form onSubmit={handleSubmit(onSubmit)} >

          {/* firstname */}
          <label className="form-label" style={{fontWeight:"300", color:"#676767",}}>Name</label>
              <div className="form-group has-icon mb-1">
              <span className="form-control-feedback"><Person /> </span>
              <input
                type='text'
                className="form-control rounded-0 paddingonLoginandSignUp" 
              {...register("firstName", {
                required: "Please Enter Name",
                minLength: {
                  value: 3,
                  message: "Name must be atleast 3 characters long...",
                },
                maxLength: {
                  value: 30,
                  message: "Name must be atmost 30 characters long...",
                },
              })}
              placeholder="Enter Your Name"
            />
          
          <p className='m-0' style={{color:"red"}}>{errors.firstName?.message}</p>
          </div>

        
        {/* Email */}

        <label className="form-label" style={{fontWeight:"300", color:"#676767",}}>Email Address</label>
          <div className="form-group has-icon mb-1">
          <span className="form-control-feedback"><Envelope /> </span>

          <input
            type="email"
            className="form-control rounded-0 paddingonLoginandSignUp"
        
          {...register("email", {
            required: "Email is Required...",
            pattern: {
              value:
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              message: "Email is not valid",
            },
          })}

          placeholder="Enter Your Email"

        />
       
          <p className='m-0' style={{color:"red"}}>{errors.email?.message}</p>

            </div>

        <label className="form-label" style={{fontWeight:"300", color:"#676767",}}>Store Name</label>
          <div className="form-group has-icon mb-1">
          <span className="form-control-feedback"><Shop /> </span>

          <input
            type="text"
            className="form-control rounded-0 paddingonLoginandSignUp"
            onChange={handleStoreNameChange}
          placeholder="Enter Your Store Name"

          />
            </div>
            {/* Username */}

        <label className="form-label" style={{fontWeight:"300", color:"#676767", fontSize:"0.8rem"}}>Your Store Link : www.iwillfly.in/store/</label>
          <div className="form-group has-icon mb-1 ms-5">
          <span className="form-control-feedback"><Link /> </span>
          
          <input
            type="text"
            className="form-control rounded-0 paddingonLoginandSignUp "
            // readOnly={true}
          {...register("username", {
            required: "Store Link is Required...",
            pattern: {
              value: /^[a-z0-9-]+$/, // Only allow lowercase letters, numbers, and hyphens
              message: "Store Link is not valid, Only allow lowercase letters, numbers, and hyphens",
            },
          })}

          placeholder="Choose Link For Your Store, Customers can see your flyers with this link"

        />
          <p className='m-0' style={{color:"red"}}>{errors.username?.message}</p>

          </div>


          {/* password */}

          <label className="form-label" style={{fontWeight:"300", color:"#676767",}}>Create New Password</label>
          <div className="form-group has-icon mb-1">
          <span className="form-control-feedback"><Eye /> </span>

          <input
          type="password"
          maxLength={20}
          autoComplete="off"
          className="form-control rounded-0 paddingonLoginandSignUp"
        
          {...register("password", {
            required: "Please Enter New Password",
            minLength: {
              value: 8,
              message: "Password must be atleast 8 characters long...",
            },
           
          })}

          placeholder="********"
        />
       
          <p className='m-0' style={{color:"red"}}>{errors.password?.message}</p>

          </div>

          <h5>Verify</h5>
          <span> <h5 style={{fontWeight:"300", fontSize:"0.8rem",}} > We have sent 6 digit OTP on <b style={{fontWeight:"500"}}>   +91-{props.mobile} </b> <span style={{color:"red",fontWeight:"500"}} onClick={ (e)=> {  window.location.reload();}} >    Change  </span>        </h5></span>  

          {/* otp */}
          <label className="form-label" style={{fontWeight:"300", color:"#676767",}}>Enter OTP</label>
          <div className="form-group has-icon mb-1">
          <span className="form-control-feedback">OTP</span>

          <input
          
          onInput={(e) => {
            const newValue = e.target.value.replace(/[^\d]/g, "");
            e.target.value = newValue;
          }}
            className="form-control rounded-0 paddingonLoginandSignUp"
            maxLength={6}
        
          {...register("otp", {
            required: "Please Enter OTP",
            minLength: {
              value: 6,
              message: "Enter 6 Digit OTP",
            },
           
          })}

          placeholder="_ _ _ _ _ _"

        />
       
          <p className='m-0' style={{color:"red"}}>{errors.otp?.message}</p>
          {disableError?null:  <span style={{color:"red"}}> {message}  </span>     }  

          </div>

          <input type="submit" className='btn btn-primary btn-lg w-100 rounded-0 p-2 shadow ' value="SignUp As Vendor"/>



      </form>



         </div>
         <p className='text-secondary text-center mt-3 text-break ' style={{fontSize:'0.7rem'}}>By Continuing you agree to our Terms And Condtions and Privacy & Legal Policy |
          <a href="mailto:support@iwillfly.com?subject=Contact Us" className="ms-1">
          Contact Us
          </a></p>
         </div>

         
    
    </>
  )
}

export default SignUp