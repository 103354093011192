import React, { useEffect, useState,useRef } from 'react'
import GeneralMenu from '../Home/GeneralMenu/GeneralMenu'
import {baseURL} from '../../Config';
import axios from 'axios';
import './FillBusinessDetails.css'
import { useForm,Controller } from "react-hook-form";
import { useNavigate } from 'react-router-dom';
import {useDispatch} from "react-redux"
import {set_user_details_details} from "../../Redux/userDetails/userDetailsSlice"
import HashLoader from "react-spinners/HashLoader";
import  {CloudArrowUpFill,CloudPlusFill} from 'react-bootstrap-icons';
import CopyRightAndTerms from '../CopyRightAndTerms/CopyRightAndTerms';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from "@mui/material/TextField";

function FillBusinessDetails() {

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const businessCatListURL='api/vendor/category/business';
  const shopCatListURL='api/vendor/category/shop';
  const [isLoading, setIsLoading] = useState(false);

  const [CatgAvailable, setCatgAvailable] = useState(false);
  const [formError , setFormError] = useState('');

  //single image
  const [image_thumbnail, setImage_thumbnail] = useState('');
   //single image error
  const [error_image_thumbnail, setError_Image_thumbnail] = useState('');
  //common form error
  
  const [addNewFlyerFormError, setAddNewFlyerFormError] = useState('');

  const image_thumbnailChange =(e)=>
  {
    console.log("image laded");
    console.log(e.target.files[0])
    console.log(e.target.files[0].size > 2097152)
    if(e.target.files[0].size > 2097152){
      setError_Image_thumbnail('Maximum Size Limit Is 2 MB , Please Choose Small Size');
      return
    }
    setImage_thumbnail(e.target.files[0]);
    setError_Image_thumbnail('');
    setAddNewFlyerFormError('')
  }

  const checkImageUploaded =()=>
  {
   
    
    if(!image_thumbnail)
    {
      setError_Image_thumbnail('Please choose one logo image')
    }
  }




  const access_token = JSON.parse(localStorage.getItem('access_token'));

  //Using async await for axios for get all reg businesss catogries
  const [optionBusinessCatg,setOptionBusinessCatg]=useState([])
  const getBusinessCatg=async()=>{
    try {
        const res = await axios.get(baseURL+businessCatListURL , { headers: {"Authorization" : `Bearer ${access_token}`} })
        if(res.data.success)
        {
            if (res.data.data.length !== 0) { setCatgAvailable(true) }
            //got all the business cat here
             setOptionBusinessCatg([])
             res.data.data.map((businessCatg)=>( setOptionBusinessCatg(optionBusinessCatg=>[...optionBusinessCatg,{value:businessCatg.id, label:businessCatg.title}])   ))
        
        }
        else{
            alert("Error Contact Administrator")
        }
    } catch (error) {
        return( error)
    }
    }

  //Using async await for axios for get all reg shop catogries
  const [optionShopCatg,setOptionShopCatg]=useState([])
  const getShopCatg=async()=>{
    try {
        const res = await axios.post(baseURL+shopCatListURL ,{}, { headers: {"Authorization" : `Bearer ${access_token}`} })
        if(res.data.success)
        {
            if (res.data.data.length !== 0) { setCatgAvailable(true) }
            //got all the shop cat here
           
            setOptionShopCatg([])
            res.data.data.map((shopCatg)=>( setOptionShopCatg(optionShopCatg=>[...optionShopCatg,{value:shopCatg.id, label:shopCatg.title}])   ))
        
        }
        else{
            alert("Error Contact Administrator")
        }
    } catch (error) {
        return( error)
    }
    }

    const [optionStateList,setOptionStateList]=useState([])
    const getStateListURL='api/vendor/states'
    const getStateList=async()=>{
      try {
          const res = await axios.get(baseURL+getStateListURL , { headers: {"Authorization" : `Bearer ${access_token}`} })
          if(res.data.success)
          {
              //got all the states list here
              setOptionStateList([])
              setOptionDistrictList([])
              res.data.data.map((stateList)=>( setOptionStateList(optionStateList=>[...optionStateList,{value:stateList.id, label:stateList.title}])   ))
          }
          else{
              alert("Error Contact Administrator")
          }
      } catch (error) {
          return( error)
      }
      }

    const [optionDistrictList,setOptionDistrictList]=useState([])
    const getDistrictListURL='api/vendor/distirct/by/state'
    const getDistrictList=async(state_id)=>{
      try {
          const res = await axios.post(baseURL+getDistrictListURL , {state_id:state_id},{ headers: {"Authorization" : `Bearer ${access_token}`} })
          if(res.data.success)
          {
              //got all the district list here
              setOptionDistrictList([])
              setOptionLocationList([])
              res.data.data.map((districtList)=>( setOptionDistrictList(optionDistrictList=>[...optionDistrictList,{value:districtList.id, label:districtList.title}])   ))
          }
          else{
              alert("Error Contact Administrator")
          }
      } catch (error) {
          return( error)
      }
      }
 
    const [valueChangedLocation,setValueChangedLocation]=useState(true)
    const [valueChangedDistrict,setValueChangedDistrict]=useState(true)

    const [optionLocationList,setOptionLocationList]=useState([])
    const getLocationListURL='api/vendor/location/by/district'
    const getLocationList=async(district_id)=>{
      try {
          const res = await axios.post(baseURL+getLocationListURL , {district_id:district_id},{ headers: {"Authorization" : `Bearer ${access_token}`} })
          if(res.data.success)
          {
              //got all the location list here
              setOptionLocationList([])
              res.data.data.map((locationList)=>( setOptionLocationList(optionLocationList=>[...optionLocationList,{value:locationList.id, label:locationList.title}])   ))
          }
          else{
              alert("Error Contact Administrator")
          }
      } catch (error) {
          return( error)
      }
      }
  
  
  
  const saveBusinessDetailsURL='api/vendor/business/details'
  //Using async await for axios for get all businesss catogries
  const saveBusinessDetails=async(data)=>{
      setIsLoading(true)
      if(error_image_thumbnail==='')
      {
        const formData = new FormData();
        formData.append('business_logo',image_thumbnail)
        formData.append('reg_business_name',data.businessRegName)
        formData.append('shop_category',data.shopCatg.value)
        formData.append('business_category',data.businessCatg.value)
        formData.append('shop_name',data.shopName)
        formData.append('gst_no',data.businessGstNo)
        formData.append('mobile_no',data.businessMobNo)
        formData.append('email_id',data.businessEmailAdress)
        formData.append('address1',data.businessAdress1)
        formData.append('address2',data.businessAdress2)
        formData.append('district',data.businessDistrict.value)
        formData.append('state',data.businessState.value)
        formData.append('location',data.location.value)
        formData.append('pin',data.businessPincode)
      
      try {
          const res = await axios.post(baseURL+saveBusinessDetailsURL ,
          formData
          , { headers: { "Authorization" : `Bearer ${access_token}`,"content-type": "multipart/form-data" }  })
       
          setIsLoading(false)
          if(res.data.success){ alert("Business Details addedd succesffull ! Please Choose Plan Again" ) ;  dispatch(set_user_details_details({details: null })) ;  navigate('/')     }
          else{  setFormError(  res.data.message   )          }
      } 
      catch (error) {
          console.log(error);
          alert(error)
          return( error)
      }
      }
      else
      {
        setAddNewFlyerFormError('Please choose images first')
      }
    }

  useEffect(() => {
    getBusinessCatg();
    getShopCatg();
    getStateList();

  }, [])

  //form
  const {control,register,handleSubmit, formState: { errors },} = useForm({mode: "all", });

  //business details submissoion
  const onSubmit=(data)=>
  {
   if(error_image_thumbnail==='')
   {
     saveBusinessDetails(data);
   }
   


  }
  const handleCloseNavMenu = () => {
    
  };
 

  return (


    <>
<div className={isLoading ? 'parentDisableCommon' : ''} width="100%">

<HashLoader color={'#0d6efd'} loading={isLoading} size={50}  cssOverride={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }} />

</div>
    <div className='container-fluid'>
      <div className='row'>

      <div className='col-3 d-none d-xl-block d-lg-block'>
          <GeneralMenu closeNavMenuFunction={handleCloseNavMenu} />
          <CopyRightAndTerms/>
          </div>

    
          <div className=' col-xl-9 col-lg-9 '>

                      <div className='container-fluid '>                     

                      <form onSubmit={handleSubmit(onSubmit)} >
                        <div className='row '>
                       
                          <div className="form-group mt-2 col-xl-6 col-lg-6 col-md-6  col-sm-12 ">
                            <label className='mb-1 formLabelNew' >Reg.Business Name</label>
                            <input type="text" className="form-control rounded-0  formInputTxt mt-1" placeholder="Business Name" 
                                          {...register("businessRegName", {
                                            required: "Please Enter Business Name",
                                            minLength: {
                                              value: 3,
                                              message: "Business Name must be atleast 3 characters long...",
                                            },
                                            maxLength: {
                                              value: 100,
                                              message: "Business Name must be atmost 100 characters long...",
                                            },
                                          })}/>
                           <p className='mt-1 formErrorNew' >{errors.businessRegName?.message}</p>
                          </div>

                          <div className="form-group mt-2 col-xl-6 col-lg-6 col-md-6  col-sm-12">
                            <label className='mb-1 formLabelNew' >Reg Business Category</label>
                            

                                <Controller
                                        control={control}
                                        name="businessCatg"
                                        rules={{ required: "Please Choose Business Category" }}
                                        render={({ field: { onChange, value } }) => (
                                          <Autocomplete
                                            onChange={(event, businessCatg) => {
                                              onChange(businessCatg);
                                            }}
                                            className='mt-1'
                                            value={value?value:null}
                                            options={optionBusinessCatg}
                                            getOptionLabel={(businessCatg) => (businessCatg.label ? businessCatg.label : "")}
                                            isOptionEqualToValue={(option, value) =>
                                              value === undefined || value === "" || option.id === value.id
                                            }
                                            renderInput={(params) => (
                                              <TextField
                                                {...params}
                                                label="Choose Business Category"
                                                 size="small"
                                              />
                                            )}
                                          />
                                        )}
                                      />



                            <p className='mt-1 formErrorNew'>{errors.businessCatg?.message}</p>
                          </div>

                          <div className="form-group mt-2 col-xl-6 col-lg-6 col-md-6  col-sm-12">
                            <label  className='mb-1 formLabelNew' >Shop Name</label>
                            <input type="text" className="form-control  rounded-0 formInputTxt mt-1" placeholder="Shop Name"
                            {...register("shopName", {
                              required: "Please Enter Shop Name",
                              minLength: {
                                value: 3,
                                message: "Shop Name must be atleast 3 characters long...",
                              },
                              maxLength: {
                                value: 100,
                                message: "Shop Name must be atmost 100 characters long...",
                              },
                            })}
                            
                            />
                             <p className='mt-1 formErrorNew'>{errors.shopName?.message}</p>
                          </div>

                          <div className="form-group mt-2 col-xl-6 col-lg-6 col-md-6  col-sm-12">
                          
                            <label className='mb-1 formLabelNew' >Shop Category</label>
                            
                            
                            <Controller
                                        control={control}
                                        name="shopCatg"
                                        rules={{ required: "Please Choose Shop Category" }}
                                        render={({ field: { onChange, value } }) => (
                                          <Autocomplete
                                            onChange={(event, shopCatg) => {
                                              onChange(shopCatg);
                                            }}
                                            className='mt-1'
                                            value={value?value:null}
                                            options={optionShopCatg}
                                            getOptionLabel={(shopCatg) => (shopCatg.label ? shopCatg.label : "")}
                                            isOptionEqualToValue={(option, value) =>
                                              value === undefined || value === "" || option.id === value.id
                                            }
                                            renderInput={(params) => (
                                              <TextField
                                                {...params}
                                                label="Choose Shop Category"
                                                 size="small"
                                              />
                                            )}
                                          />
                                        )}
                                      />
                            <p className='mt-1 formErrorNew'>{errors.shopCatg?.message}</p>
                          </div>


                          <div className="form-group mt-2 col-xl-6 col-lg-6 col-md-6  col-sm-12">
                            <label className='mb-1 formLabelNew' >GST Number</label>
                            <input type="text" className="form-control  rounded-0 formInputTxt" placeholder="GSTIN326545645"
                             maxLength={15}
                                                        {...register("businessGstNo", {
                                                          required: "Please Enter GST No",
                                                          minLength: {
                                                            value: 15,
                                                            message: "GST Number Not Valid (15 length)",
                                                          },
                                                          maxLength: {
                                                            value: 15,
                                                            message: "GST Number Not Valid (15 length).",
                                                          },
                                                        })}
                            />
                            <p className='mt-1 formErrorNew'>{errors.businessGstNo?.message}</p>
                          </div>           

                          <div className="form-group mt-2 col-xl-6 col-lg-6 col-md-6  col-sm-12">
                            <label  className='mb-1 formLabelNew'>Business Mobile Number</label>
                            <input type="text" className="form-control  rounded-0 formInputTxt" placeholder="+91 89335 55623"
                                onInput={(e) => {
                                  const newValue = e.target.value.replace(/[^\d]/g, "");
                                  e.target.value = newValue;
                                }}

                              maxLength={10}
                              {...register("businessMobNo", {
                                required: "Please Enter Mobile Number",
                                minLength: {
                                  value: 10,
                                  message: "Mobile Number Not Valid (10 length)",
                                },
                                maxLength: {
                                  value: 10,
                                  message: "Mobile Number Not Valid (10 length).",
                                },
                              })}
                            
                            
                            />
                          <p className='mt-1 formErrorNew'>{errors.businessMobNo?.message}</p>
                          </div>

                          <div className="form-group mt-2 col-xl-6 col-lg-6 col-md-6  col-sm-12">
                            <label className='mb-1 formLabelNew' >Business Email Id</label>
                            <input type="email" className="form-control  rounded-0 formInputTxt" placeholder="enter email address"

                            {...register("businessEmailAdress", {
                              required: "Please Enter Email Address ",
                              minLength: {
                                value: 3,
                                message: "Email Address Not Valid ",
                              },
                            })}
                            
                            />
                            <p className='mt-1 formErrorNew'>{errors.businessEmailAdress?.message}</p>

                          </div>

                          <div className="form-group mt-2 col-xl-6 col-lg-6 col-md-6  col-sm-12">
                          <div className='container shadow rounded p-5 text-center '>
                            <label style={{cursor:'pointer'}} htmlFor='image_thumbnail'> <CloudArrowUpFill size={36} color='#6c757d'/> 
                            
                            <p className='text-break ' style={{fontWeight:"200",fontSize:'0.9rem'}}>Upload Business Logo ( 1 : 1 Ratio or 256px X 256px) </p></label>
                            <input type='file' accept=".png, .jpg, .jpeg"   name='image_thumbnail' id='image_thumbnail' className='d-none' onChange={(e)=>image_thumbnailChange(e)} />
                            {/* Image uploaded */}
                            {image_thumbnail?
                            <div className="card mt-2" style={{fontSize:'0.8rem'}}>
                                <div className="card-header">
                                Business Logo 
                                </div>
                                <ul className="list-group list-group-flush" style={{fontSize:'0.8rem'}}>
                                  <li className="list-group-item">{image_thumbnail.name}</li>
                                </ul>
                              </div>:null}

                              
                            {/* error */}
                            {error_image_thumbnail?<><p style={{color:'red',fontSize:'0.6rem'}}> {error_image_thumbnail} </p></>:null}
                          </div>
                          </div>

                          <div className="form-group mt-2 col-12">
                            <label className='mb-1 formLabelNew' >Business Address</label>
                            <input type="text" className="form-control  rounded-0 formInputTxt mb-2" placeholder="Address line 1"
                                                        {...register("businessAdress1", {
                                                          required: "Please Enter  Address ",
                                                          minLength: {
                                                            value: 3,
                                                            message: "Address Not Valid ",
                                                          },
                                                        })}
                            
                            />
                          <p className='mt-1 formErrorNew'>{errors.businessAdress1?.message}</p>

                            <input type="text" className="form-control  rounded-0 formInputTxt" placeholder="Address line 2"
                                                                                    {...register("businessAdress2", {
                                                                                      required: "Please Enter  Address ",
                                                                                      minLength: {
                                                                                        value: 3,
                                                                                        message: "Address Not Valid ",
                                                                                      },
                                                                                    })}
                            
                            />

                          <p className='mt-1 formErrorNew'>{errors.businessAdress2?.message}</p>
                          </div>


                          <div className="form-group mt-2 col-xl-6 col-lg-6 col-md-6  col-sm-12">
                            <label className='mb-1 formLabelNew' >State</label>

                            <Controller
                                        control={control}
                                        name="businessState"
                                        rules={{ required: "Please Choose State" }}
                                        render={({ field: { onChange, value } }) => (
                                          <Autocomplete
                                            onChange={(event, businessState) => {
                                              onChange(businessState);
                                              getDistrictList(businessState.value)
                                              setValueChangedDistrict(true);
                                              setValueChangedLocation(true) 
                                            }}
                                            className='mt-1'
                                            value={value?value:null}
                                            options={optionStateList}
                                            getOptionLabel={(businessState) => (businessState.label ? businessState.label : "")}
                                            isOptionEqualToValue={(option, value) =>
                                              value === undefined || value === "" || option.id === value.id
                                            }
                                            renderInput={(params) => (
                                              <TextField
                                                {...params}
                                                label="Choose State"
                                                 size="small"
                                              />
                                            )}
                                          />
                                        )}
                                      />

                            <p className='mt-1 formErrorNew'>{errors.businessState?.message}</p>
                          </div>

                          <div className="form-group mt-2 col-xl-6 col-lg-6 col-md-6  col-sm-12">
                              <label className='mb-1 formLabelNew' >District</label>

                            
                            <Controller
                                        control={control}
                                        name="businessDistrict"
                                        rules={{ required: "Please Choose District" }}
                                        render={({ field: { onChange, value, } }) => (
                                          <Autocomplete
                                            onChange={(event, businessDistrict) => {
                                              onChange(businessDistrict);
                                              setValueChangedLocation(true) 
                                              setValueChangedDistrict(false)                                                
                                              getLocationList(businessDistrict.value)
                                            }}
                                           
                                            className='mt-1'
                                            value={valueChangedDistrict?'':value}
                                            options={optionDistrictList}
                                            getOptionLabel={(businessDistrict) => (businessDistrict.label ? businessDistrict.label : "")}
                                            isOptionEqualToValue={(option, value) =>
                                              value === undefined || value === "" || option.id === value.id
                                            }
                                            renderInput={(params) => (
                                              <TextField
                                                {...params}
                                                label="Choose District"
                                                 size="small"
                                              />
                                            )}
                                          />
                                        )}
                                      />

                              <p className='mt-1 formErrorNew'>{errors.businessDistrict?.message}</p>
                          </div>

                          <div className="form-group mt-2 col-xl-6 col-lg-6 col-md-6  col-sm-12">
                          <label className='mb-1 formLabelNew' >Location</label>

                          {/* <select className="form-control  rounded-0 formInputTxt"                                    
                             {...register("location", 
                             { required: "Please Select Location",
                                          })}   >
                                            
                              <option value={''}hidden  >--Choose Option --</option>
                               {(locationList)?(
                                          locationList.map((location,index)=>
                                                {
                                                return(<option key={index} value={location.id}> {location.title} </option> )
                                                }
                                )):<option value={''} disabled  >Select District First</option>     }
                            </select> */}


                            <Controller
                                        control={control}
                                        name="location"
                                        rules={{ required: "Please Choose Location" }}
                                        render={({ field: { onChange, value  } }) => (
                                          <Autocomplete
                                            
                                            onChange={(event, location) => {
                                            
                                              onChange(location);
                                              setValueChangedLocation(false) 
                                            }}
                                            className='mt-1'
                                            value={valueChangedLocation?'':value}
                                            
                                            options={optionLocationList}
                                            getOptionLabel={(location) => (location.label ? location.label : "")}
                                            isOptionEqualToValue={(option, value) =>
                                              value === undefined || value === "" || option.id === value.id
                                            }
                                            renderInput={(params) => (
                                              <TextField
                                                {...params}
                                                
                                                label=" Choose Location"
                                                 size="small"
                                              />
                                            )}
                                          />
                                        )}
                                      />




                           <p className='mt-1 formErrorNew'>{errors.location?.message}</p>
                          </div>
                          
                          <div className="form-group mt-2 col-xl-4 col-lg-4 col-md-4  col-sm-12">
                            <label  className='mb-1 formLabelNew'>Pincode</label>
                            <input type="text" className="form-control  rounded-0 formInputTxt" placeholder="pincode"

                               {...register("businessPincode", {
                                required: "Please Enter Pincode ",
                                  minLength: {
                                    value: 3,
                                message: "Pincode Not Valid ",
                                  },
                                })}
                            
                            />
                            <p className='mt-1 formErrorNew'>{errors.businessPincode?.message}</p>
                          </div>

                          <div className="form-group mt-3 mb-2 col-12">      
                            <input type="submit" className="form-control  rounded-0 btn btn-primary" onClick={checkImageUploaded} />
                          </div>

                        </div>
                        <p className='mt-1 formErrorNew'>{formError?formError:null}</p>
                        </form>

                    </div>
                       


          </div>

      </div>
    </div>
    </>


  )
}

export default FillBusinessDetails